import React from "react";
import "./Button.scss";
function Button({ onclick, title, type, disabled }) {
  return (
    <button
      className={disabled === true ? "disabled-btn" : "primary-btn"}
      onClick={onclick}
      type={type}
      disabled={disabled}
    >
      {title}
    </button>
  );
}

export default Button;
