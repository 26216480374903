import "./HeadingText.scss"
import React from 'react'
import { Box } from "@mui/material"

function HeadingText({title,primary}) {
  return (
    <Box className="heading-text" 
    // sx={{mx:2,my:1}}
    >
      <span className={primary&&"color-primary"}>

      {title}
      </span>
      </Box>
    
  )
}

export default HeadingText