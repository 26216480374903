import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Card.scss";
import React, { useEffect, useState } from "react";
import HeadingText from "../HeadingText/HeadingText";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TableContainer from "@mui/material/TableContainer";
import axios from "axios";

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import Button from "../Button/Button";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import * as Yup from "yup";

const itemlist = {
    item: [
        "False Ceiling",
        "Flooring",
        "Kitchen Cabinet",
        "Kitchen Shutters",
        "Kitchen Fittings",
        "Wardrobe cabinet",
        "Wardrobe Finish",
        "Wardrobe Fittings",
        "Washroom Fittings",
        "Electrical Wires/Switches/Lights",
        "Paint",
        "Hard & Soft Furnitures",
    ],
    Classic: [
        "Gypsum",
        "Unbranded Tiles",
        "MDF",
        "Mica",
        "Unbranded",
        "MDF",
        "Mica",
        "Unbranded",
        "Unbranded",
        "RR cables/L&T normal/Panasonic",
        "Asian Apcolite",
        "As per Customer Choice",
    ],
    Premium: [
        "POP",
        "Branded Tiles/Marble/Granite",
        "HDHMR",
        "PVC Laminate/Acrylic",
        "Indian brand",
        "BWR ply",
        "PVC Laminate/Acrylic",
        "Indian Brand",
        "Indian brand",
        "Polycab/L&T medium/Opel",
        "Asian Royale",
        "As per Customer Choice",
    ],
    Luxury: [
        "POP/Wooden",
        "Italian Marble/Granite",
        "BWP ply",
        "PVC laminate/Acrylic/Deco",
        "Imported brand",
        "BWP ply",
        "PVC Laminate/Acrylic/Glass",
        "Imported Brand",
        "Imported Brand",
        "Havelles/L&T Premium/Phillips",
        "Asian Royal Sign & Above",
        "As per Customer Choice",
    ],
};
const plan = [
    {
        plantype: "Classic Plan",
        totalcost: 1500,
        fontColorTheme: "#66bfbf"
    },
    {
        plantype: "Premium Plan",
        totalcost: 2200,
        fontColorTheme: "#0092ca"
    },
    {
        plantype: "Luxury Plan",
        totalcost: 3000,
        fontColorTheme: "#005792"
    },
];

function Card({ changediv, step }) {
    const [selecetedLocation, setSelecetedLocation] = React.useState("");
    function handleChangeOfSelecetedLocation(event) {
        const { name, value } = event.target
        setSelecetedLocation(value)
    }

    const [selecetedapartmenttype, setselecetedapartmenttype] =
        React.useState("");
    const [step1disability, setstep1disability] = React.useState(true);
    const [modalbtndisable, setmodalbtndisable] = React.useState(true);
    const [plantype, setplantype] = useState(null);
    //const [open, setOpen] = useState(false);
    const [open, setOpen] = useState(true);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    function moveprev(step) {
        changediv(step, -1);
    }
    function movenext(step) {
        changediv(step, 1);
    }
    function selectplantype(plan) {
        setplantype(plan);
    }
    const phoneRegExp = /^\d{10}$/;

    const SignupSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required("Required"),
        carpetarea: Yup.number(),
        email: Yup.string()
            .email("Enter valid email")
            .matches(/^\S+@\S+\.\S+$/, "Enter valid email"),
        contactnumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required("Required"),
    });
    const formik = useFormik({
        initialValues: {
            apartmenttype: "",
            carpetarea: "",
            plan: "",
            totalcost: "",
            name: "",
            contactnumber: "",
            email: "",
        },
        validationSchema: SignupSchema,
        onSubmit: (values) => {
            //console.log(values);
            notify("Team will contact. Thanks for choosing us.");
        },
    });


    const handleBlockEstimatedUserSubmission = () => {
        if (
            formik.errors.name === undefined &&
            formik.errors.contactnumber === undefined &&
            formik.values.name !== "" &&
            formik.values.contactnumber !== ""
        ) {
            const requestBodyJsonObj =
            {
                name: formik.values.name,
                mobileNumber: formik.values.contactnumber,
                emailId: formik.values.email,
            };
            axios
                .post(
                    `https://webapi.worthspace.com/api/WorthSpace/v1/BlockEstimatedUser`,
                    requestBodyJsonObj 
                )
                .then((res) => {
                    console.log(res);
                    console.log(res.data);
                });
            handleClose();
        } else {
            console.log("see1: " + formik.errors.name);
            console.log("see2: " + formik.errors.contactnumber);
        }
    }

    function notify(message) {
        console.log(message);
        toast(message);
    }

    function step1btn() {
        if (formik.values.apartmenttype && formik.values.carpetarea > 0) {
            setstep1disability(false);
        } else {
            if (formik.values.apartmenttype === "") {
                setstep1disability(true);
            }
            if (formik.values.carpetarea === "") {
                setstep1disability(true);
            }
            if (formik.values.carpetarea < 0) {
                setstep1disability(true);
            }
        }
    }
    function modalbtn() {
        if (formik.values.name !== "" && formik.values.contactnumber !== "") {
            setmodalbtndisable(false);
        } else {
            setmodalbtndisable(true);
        }
    }

    const selectplan = `  ( Apartment type: ${formik.values.apartmenttype} ,  Carpet area: ${formik.values.carpetarea} SqFt )`;
    //console.log("check", formik);
    useEffect(() => {
        step1btn();
    }, [formik.values.apartmenttype, formik.values.carpetarea]);
    useEffect(() => {
        modalbtn();
    }, [formik.values.name, formik.values.contactnumber]);
    return (
        <>
            <ToastContainer />
            <form onSubmit={formik.handleSubmit}>
                {step === 1 && (
                    <Box className="card card-border">
                        <Box className="card-heading card-line card-content">
                            {/* <HeadingText title="Area/Locality" /> */}
                            <TextField
                                id="outlined-basic"
                                label="Area / Location"
                                variant="outlined"
                                className="inputfield"
                                value={selecetedLocation}
                                onChange={handleChangeOfSelecetedLocation}
                            />
                        </Box>
                        <Box>
                            <Grid container spacing={2} className="card-line card-content">
                                <Grid item xs={12} md={5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        Type of Apartment
                                    </Typography>
                                    <br></br>
                                    <FormControl fullWidth>
                                        <Select
                                            id="demo-simple-select"
                                            value={selecetedapartmenttype}
                                            {...formik.getFieldProps("apartmenttype")}
                                        >
                                            <MenuItem value={"2 BHK"}>2 BHK</MenuItem>
                                            <MenuItem value={"2.5 BHK"}>2.5 BHK</MenuItem>
                                            <MenuItem value={"3 BHK"}>3 BHK</MenuItem>
                                            <MenuItem value={"3.5 BHK"}>3.5 BHK</MenuItem>
                                            <MenuItem value={"4 BHK"}>4 BHK</MenuItem>
                                            <MenuItem value={"5 BHK"}>5 BHK</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Typography sx={{ fontSize: "14px" }}>Carpet Area</Typography>
                                    <br></br>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="outlined-basic"
                                            label="Carpet Area (in SqFt)"
                                            variant="outlined"
                                            {...formik.getFieldProps("carpetarea")}
                                            type="number"
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Box className="actionbtn card-content">
                                <Button
                                    onclick={() => {
                                        if (
                                            formik.values.apartmenttype != null &&
                                            formik.values.carpetarea > 0
                                        ) {
                                            movenext(2);
                                        } else {
                                            if (formik.values.apartmenttype === "") {
                                                notify("Apartmentrequired");
                                            }
                                            if (formik.values.carpetarea === "") {
                                                notify("carpetvaluerequired");
                                            }
                                            //console.log(formik.values);
                                        }
                                    }}
                                    title="Next"
                                    type="button"
                                    disabled={step1disability}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}
                {step === 2 && (
                    <Box className="card card-border">
                        <Box className="card-heading card-line card-content">
                            <HeadingText title="Select Plan" />
                        </Box>
                        <Box className="card-content card-line">
                            <Grid container>
                                {plan.map((item, index) => (
                                    <Grid
                                        md={4}
                                        lg={4}
                                        sm={12}
                                        item
                                        className="plans"
                                        key={index}
                                    >
                                        <Box className="plans-card">
                                            <div className="text-bold">Plan type</div>
                                            <div className="text-bold" style={{ fontSize: "30px", color: item.fontColorTheme }}>
                                                {item.plantype}
                                            </div>
                                            {/* <div className="text-bold">Estimated Cost</div>
                      <div className="text-plain">{item.totalcost} rs</div> */}
                                            <div className="text-price-bold">{item.plantype}</div>
                                            <div className="text-price">
                                                Rs{" "}
                                                {new Intl.NumberFormat("en-IN").format(
                                                    item.totalcost * formik.values.carpetarea
                                                )}
                                            </div>
                                            {/* <div className="text-price-plain">INR</div> */}

                                            <Button
                                                name="plan"
                                                onclick={() => {
                                                    movenext(3);
                                                    selectplantype(item.plantype);
                                                    formik.values.plan = item.plantype;
                                                    formik.values.totalcost = item.totalcost;
                                                }}
                                                title="Choose"
                                            />
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>

                        <Box className="previousbtn card-content">
                            <Button
                                onclick={() => {
                                    moveprev(1);
                                }}
                                title="Go Back"
                            />
                        </Box>
                    </Box>
                )}
                {step === 3 && (
                    <Box className="card card-border">
                        <Box
                            className="card-content card-line"
                            mx={{ xs: 1, sm: 1, md: 5, lg: 20 }}
                        >
                            <Box className="">
                                <Box className="text-bold" sx={{ mt: 1 }}>
                                    {formik.values.plan}:{selectplan}
                                </Box>
                                <Box className="text-price text-bold">
                                    Rs{" "}
                                    {new Intl.NumberFormat("en-IN").format(
                                        formik.values.carpetarea * formik.values.totalcost
                                    )}
                                    {/* nfObject = new Intl.NumberFormat('en-US'); 
        output = nfObject.format(givenNumber);  */}
                                </Box>
                                {/* <Box className="text-plain">INR</Box> */}
                            </Box>
                            <Box sx={{ pt: 3 }} className="text-bold scrollablex">
                                Tentative List of Services/Items provided
                            </Box>
                            <TableContainer>
                                <Table className="price-table ">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className="tableHeadCell">Seq</TableCell>
                                            <TableCell className="tableHeadCell">
                                                Service or Items Provided
                                            </TableCell>
                                            {formik.values.plan && (
                                                <TableCell className="tableHeadCell">
                                                    {formik.values.plan}
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {itemlist.item.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>{item}</TableCell>
                                                {formik.values.plan === "Classic Plan" && (
                                                    <TableCell>{itemlist.Classic[index]}</TableCell>
                                                )}
                                                {formik.values.plan === "Premium Plan" && (
                                                    <TableCell>{itemlist.Premium[index]}</TableCell>
                                                )}
                                                {formik.values.plan === "Luxury Plan" && (
                                                    <TableCell>{itemlist.Luxury[index]}</TableCell>
                                                )}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box
                            className="previousbtn card-content card-line"
                            display={"flex"}
                            justifyContent="space-between"
                            flexWrap="wrap"
                        >
                            <Button
                                onclick={() => {
                                    moveprev(2);
                                }}
                                title="Go Back"
                            />
                            {/* <Button
                onclick={() => {
                  formik.handleSubmit();
                }}
                type="button"
                title="Submit"
              /> */}
                        </Box>
                    </Box>
                )}
                {/* Modal */}
                <div>
                    <Modal
                        open={open}
                        
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                bgcolor: "transparent",
                                width: "100%",
                            }}
                            py={{ xs: 30, md: 60, lg: 20 }}
                        >
                            <Box
                                sx={{
                                    border: "2px solid #000",
                                    bgcolor: "white",
                                    padding: 4,
                                }}
                                width={{ lg: 700, sm: 500, xs: 300 }}
                                className="card card-border"
                            >
                                <FormControl fullWidth>
                                    <TextField
                                        id="outlined-basic"
                                        label="Name"
                                        variant="outlined"
                                        className="inputfield"
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.errors.name ? <div>{formik.errors.name}</div> : null}
                                    <TextField
                                        id="outlined-basic"
                                        label="Contact Number"
                                        variant="outlined"
                                        className="inputfield"
                                        {...formik.getFieldProps("contactnumber")}
                                    />
                                    {formik.errors.contactnumber ? (
                                        <div>{formik.errors.contactnumber}</div>
                                    ) : null}
                                    <TextField
                                        id="outlined-basic"
                                        label="Email (optional)"
                                        variant="outlined"
                                        className="inputfield"
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.errors.email ? (
                                        <div>{formik.errors.email}</div>
                                    ) : null}
                                    <Box
                                        className="previousbtn card-line"
                                        display={"flex"}
                                        justifyContent="space-between"
                                        flexWrap="wrap"
                                    >
                                        {/* <Button
                      onclick={handleClose}
                      type="button"
                      title="Cancel"
                    /> */}
                                        <Box></Box>
                                        <Button
                                            onclick={handleBlockEstimatedUserSubmission}
                                            /*onclick={() => {
                                                if (
                                                    formik.errors.name === undefined &&
                                                    formik.errors.contactnumber === undefined &&
                                                    formik.values.name !== "" &&
                                                    formik.values.contactnumber !== ""
                                                ) {
                                                    handleClose();
                                                } else {
                                                    console.log(formik.errors.name);
                                                    console.log(formik.errors.contactnumber);
                                                }
                                            }}*/
                                            type="submit"
                                            title="Next"
                                            disabled={modalbtndisable}
                                        />
                                    </Box>
                                </FormControl>
                            </Box>
                        </Box>
                    </Modal>
                </div>
            </form>
        </>
    );
}

export default Card;
