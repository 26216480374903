import { Box } from "@mui/material";
import React from "react";

function Note() {
  return (
    <>
      <Box className="note-description" sx={{ fontSize: "12px" }}>
        <b>
          👋 Note:
          <p>
            The above cost is a Block estimate & Final Costing/Quotation will be
            calculated based on Final 3D design approved by the customer
          </p>
          <p>
            The cost of hard & soft Furnitures will depend on the customer
            choice & the same is not included in the Block estimate
          </p>
          <p>The above Block estimate comprise of labour & material cost</p>
          <p>Civil work will be charged extra on Job basis</p>
          <p>Standard wardrobe size of 7'x4'x2'</p>
          <p>Wall panelling cost will depend on the area of panelling</p>
          <p>Kitchen will be Modular with Fittings & Finish</p>
        </b>
      </Box>
    </>
  );
}

export default Note;
