import "./App.scss";
import Navbar from "./components/Navbar/Navbar";
import CustomizedSteppers from "./components/Steps/CustomizedSteppers";
import Steps from "./components/Steps/Steps";

function App() {
  return (
    <>
      <Navbar />
      <Steps />
      {/* <CustomizedSteppers /> */}
    </>
  );
}

export default App;
