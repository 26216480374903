import "./Steps.scss";
import React, { useState } from "react";
import Card from "../Card/Card";
import HeadingText from "../HeadingText/HeadingText";
import { Box } from "@mui/system";
import Note from "../Note/Note";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Step, StepLabel, Stepper } from "@mui/material";
const stepsvalue = ["Step 1", "Step 2", "Step 3"];
function Steps() {
  const [stepvalue, setstepvalue] = useState(1);
  function movestep(step, nav) {
    setstepvalue(step);
    console.log(nav);
    if (nav === -1) handleBack();
    if (nav === 1) handleNext();
  }

  //stepper
  const [activeStep, setActiveStep] = useState(0);
  const [activescroll, setactivescroll] = useState("scrollable");

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#f7be68",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#f7be68",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#c2c2c2",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

  //console.log("stepsvalue", stepvalue);
  return (
    <>
      <Box className="main-card card-border">
        <Box className="card-heading card-line ">
          <HeadingText title="Cost Estimation" primary={true} />
        </Box>

        {/* <Box className={stepsvalue == 3 ? activescroll : "noscroll"}> */}
        <Box>
          <Box
            px={{ xs: 3, sm: 15, md: 30, lg: 50 }}
            sx={{ py: 2 }}
            className="card-line"
          >
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<QontoConnector />}
            >
              {stepsvalue.map((label, index) => {
                return (
                  <Step
                    key={label}
                    sx={{ fontSize: "11px", fontWeight: "600" }}
                  >
                    {/* <StepLabel StepIconComponent={CustomStepIcon}> */}
                    <StepLabel>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
          <Box>
            <Card step={stepvalue} changediv={movestep} />
          </Box>
        </Box>
      </Box>
      <Note />
    </>
  );
}

export default Steps;
