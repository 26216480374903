import {
  AppBar,
  Box,
  Container,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import brandimg from "../../assets/png/worthspace.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
function Navbar() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("lg"));
  const [opendrawer, setopendrawer] = useState(false);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#201e1e" }}>
        <Container maxWidth="lg">
          <Toolbar>
            <img
              src={brandimg}
              style={{ height: "7vh", paddingTop: "2vh" }}
              alt="Worth Space Block Estimate"
            />
            {/* <Tabs textColor="#fff" sx={{ marginLeft: "auto" }}>
              {matches === true ? (
                <IconButton
                  onClick={() => {
                    setopendrawer(!opendrawer);
                  }}
                  sx={{
                    color: "white",
                    display: "flex",
                    flexDirection: "row",
                    alignContent: "center",
                  }}
                >
                  <MenuIcon />
                </IconButton>
              ) : (
                <>
                  <Tab label="Home" />
                  <Tab label="About Us" />
                  <Tab label="Our Portfolio" />
                  <Tab label="Career" />
                  <Tab label="Blog" />
                  <Tab label="Contact Us" />
                  <Tab label="684864768768" />
                </>
              )}
            </Tabs> */}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        open={opendrawer}
        onClose={() => {
          setopendrawer(!opendrawer);
        }}
      >
        <>
          <List>
            <ListItemButton>
              <ListItemText>Home</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>About Us</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>Our Portfolio</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>Career</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>Blog</ListItemText>
            </ListItemButton>
            <ListItemButton>
              <ListItemText>Contact Us</ListItemText>
            </ListItemButton>
          </List>
        </>
      </Drawer>
    </Box>
  );
}

export default Navbar;
